<template>
	<div class="o-layout o-layout--large">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<recess-button :title="BUTTON_TEXT.goBack" icon="back" variant="variant4" class="qa-go-back-button" url="/result" />
					<title-text :title="PROVIDER.ResultsAndContractsPage.OrderFeesPage.DetailsTitle" class="qa-order-fees-overview-title" />
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<recess-card variant="variant1">
						<div class="container-fluid">
							<div class="row">
								<div class="col-4">
									<recess-multi-select
										:selected-options="selectedFilters.feeStatus"
										:options="orderFeeStatusOptions"
										:single-select="true"
										:label-text="PROVIDER.ResultsAndContractsPage.OrderFeesPage.FilterInput.OrderFeeStatus.Label"
										:select-options-text="orderFeeStatusOptions && orderFeeStatusOptions[0].displayText"
										class="qa-order-fee-status-dropdown"
										@input="(newValue) => setSpecificFilter('feeStatus', newValue)"
									/>
								</div>
								<div class="col-4">
									<div class="d-flex">
										<recess-date-picker
											v-model="selectedFilters.minDateCreated"
											:default-value="selectedFilters.minDateCreated"
											:label-text="PROVIDER.ResultsAndContractsPage.OrderFeesPage.FilterInput.MinDateCreated.Label"
											:error-message="minDateCreatedError"
											class="qa-order-fee-min-date-created-filter"
											@input="(newValue) => setMinDateCreated(newValue)"
											date-picker-input-custom-width="170px"
										/>

										<recess-date-picker
											v-model="selectedFilters.maxDateCreated"
											:default-value="selectedFilters.maxDateCreated"
											:label-text="PROVIDER.ResultsAndContractsPage.OrderFeesPage.FilterInput.MaxDateCreated.Label"
											:error-message="maxDateCreatedError"
											class="ml-4 qa-order-fee-max-date-created-filter"
											@input="(newValue) => setMaxDateCreated(newValue)"
											date-picker-input-custom-width="170px"
										/>
									</div>
								</div>

								<div class="col-4 d-flex align-items-end justify-content-end"> 
									<recess-button
										variant="tertiary"
										:title="PROVIDER.ResultsAndContractsPage.OrderFeesPage.ResetFilters"
										class="ml-4 qa-reset-filters"
										@click.native.prevent="resetFilters()"
									/>
								</div>
							</div>
						</div>
					</recess-card>
				</div>
			</div>

			<div class="row">
				<order-fee-list class="col-12" :set-selected-filters="localFilters" />
			</div>
		</div>
	</div>
</template>

<script>
import dayjs from 'dayjs'
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { getEnumList } from '@/../../shared/api/SharedClient'
import { toISODateString, validateFullDate } from '@/utils/dateTimeHelper'
import { API_CALL_URL_PATHS, BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { dateRangeHasErrorMessage } from '@/../../shared/constants/validationMessageHelper.js'

import TitleText from '@/components/atoms/TitleText'
const OrderFeeList = () => import('@/components/organisms/Result/OrderFeeList')

import filterMixin from '@/../../shared/mixins/filterMixin'
import { isInvalidErrorMessage } from '../../../../shared/constants/validationMessageHelper'

export default {
	name: 'OrderFeesOverview',
	components: {
		TitleText,
		OrderFeeList
	},
	mixins: [validationMixin, filterMixin],
	validations: {
		selectedFilters: {
			minDateCreated: {
				validDate(value, selectedFilters) {
					if (!selectedFilters.minDateCreated) return true
					return validateFullDate(value)
				},
				minValue(value, selectedFilters) {
					// if minDateCreated isnt defined return true since this field isnt mandatory
					if (!selectedFilters.minDateCreated) return true

					// If maxDateCreated is defined the minDateCreated value needs to be:
					// -- smaller than maxDateCreated
					if (selectedFilters.maxDateCreated) {
						const startDate = toISODateString(selectedFilters.minDateCreated)
						const endDate = toISODateString(selectedFilters.maxDateCreated)
						return dayjs(startDate).isSameOrBefore(endDate)
					}

					return true
				}
			},
			maxDateCreated: {
				validDate(value, selectedFilters) {
					if (!selectedFilters.maxDateCreated) return true
					return validateFullDate(value)
				},
				minValue(value, selectedFilters) {
					// if maxDateCreated isnt defined return true since this field isnt mandatory
					if (!selectedFilters.maxDateCreated) return true

					// If maxDateCreated is defined it needs to be:
					// -- bigger than minDateCreated
					if (selectedFilters.minDateCreated) {
						const startDate = toISODateString(selectedFilters.minDateCreated)
						const endDate = toISODateString(selectedFilters.maxDateCreated)
						return dayjs(endDate).isSameOrAfter(startDate)
					}

					return true
				}
			}
		}
	},
	data() {
		return {
			BUTTON_TEXT,
			PROVIDER,
			orderFeeStatusOptions: [{
				displayText: PROVIDER.ResultsAndContractsPage.OrderFeesPage.FilterInput.OrderFeeStatus.SelectText,
				value: 'All'
			}],
			selectedFilters: {
				feeStatus: 'All',
				minDateCreated: null,
				maxDateCreated: null,
				providerId: localStorage.getItem('providerId')
			} 
		}
	},
	computed: {
		...mapState('providerModule', ['selectedProvider']),
		minDateCreatedError() {
			return (
				isInvalidErrorMessage('Datum', this.$v.selectedFilters.minDateCreated, 'validDate') ||
				dateRangeHasErrorMessage(
					'minDate',
					this.$v.selectedFilters.minDateCreated,
					'minValue',
					{startDate: 'begindatum ', endDate: 'einddatum'}
            	)
			)
		},
		maxDateCreatedError() {
			return (
				isInvalidErrorMessage('Datum', this.$v.selectedFilters.maxDateCreated, 'validDate') ||
				dateRangeHasErrorMessage(
					'maxDate',
					this.$v.selectedFilters.maxDateCreated,
					'minValue',
					{startDate: 'begindatum ', endDate: 'einddatum'}
				)
			)
		}
	},
	watch: {
		selectedProvider: {
			handler() {
				if (this.selectedProvider && this.selectedFilters && this.selectedProvider.id !== this.selectedFilters.providerId) {
					this.setSpecificFilter('providerId', this.selectedProvider.id) 
				}
			},
			deep: true
		}
	},
	created() {
		this.getOrderFeeStatusOptions()
	},
	methods: {
		setMinDateCreated(newValue) {
			if(this.minDateCreatedError) return

			this.setSpecificFilter('minDateCreated', newValue)
		},

		setMaxDateCreated(newValue) {
			if(this.maxDateCreatedError) return

			this.setSpecificFilter('maxDateCreated', newValue)
		},
		async getOrderFeeStatusOptions() {
			try {
				const response = await getEnumList(process.env.VUE_APP_ORDER_API_URL, API_CALL_URL_PATHS.enumerations, 'orderFeeStatus', null, null, false)
				if (!response) return
				this.orderFeeStatusOptions = [...this.orderFeeStatusOptions, ...response]
			} catch (error) {
				console.error('Something went wrong while retrieving order fee status enum list', error)
			}
		},  
		resetFilters() { 
			this.selectedFilters = {
				feeStatus: 'All',
				minDateCreated: null,
				maxDateCreated: null,
				providerId: null
			}

			this.localFilters = JSON.parse(JSON.stringify(this.selectedFilters))
		}
		
	}
}
</script>
